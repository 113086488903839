@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=BIZ+UDPGothic:wght@400;700&display=swap');

.responsive-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
}

@media (max-width: 800px) {
  .responsive-grid {
    grid-template-columns: repeat(4, 1fr);
  }
  .responsive-width {
    width: 95% !important;
  }
}

@media (max-width: 600px) {
  .responsive-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 400px) {
  .responsive-grid {
    grid-template-columns: 1fr;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'BIZ UDPGothic', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #3e2723;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
